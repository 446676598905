import React from 'react';
import { useLocation } from '@reach/router';
import { Box, Center, chakra } from '@chakra-ui/react';
import Headroom from 'react-headroom';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import { BaseSearch } from '@components/base';
import { CoreLogo } from './core-logo.component';
import { CoreNav } from './core-nav.component';
import { CoreBreadcrumb } from './core-breadcrumb.component';
import { CoreSubmitLink } from './core-submit-link.component';

function RCoreHeader({
  siteUrl,
  siteTitle,
  primaryMenuLinks,
  footerMenuLinks,
}) {
  const { pathname, search } = useLocation();
  const [searchKeyword, setSearchKeyword] = React.useState('');
  const isHome = pathname === '/';

  const submitLink = React.useMemo(
    () =>
      primaryMenuLinks.find((link) => link.label.toLowerCase() === 'submit')
        ?.to || '',
    [primaryMenuLinks]
  );

  React.useEffect(() => {
    if (pathname !== '/search') return;
    const { q } = queryString.parse(search);
    setSearchKeyword(q);
  }, [pathname, search]);

  const handleSearchChange = React.useCallback((val) => {
    const { value } = val.target;
    setSearchKeyword(value);
  }, []);

  const handleSearchSubmit = React.useCallback(
    (event) => {
      event.preventDefault();
      const params =
        searchKeyword.trim() &&
        `?q=${searchKeyword.trim().replace(/ /g, '%20')}`;

      // navigate(`/search${params}`);
      window.location.href = `/search${params}`;
    },
    [searchKeyword]
  );

  return (
    <Headroom style={{ zIndex: 100001 }}>
      <chakra.header
        className='core-header'
        w='full'
        h={{ base: '48px', sm: '56px' }}
      >
        <Box
          pos='absolute'
          top={0}
          right={0}
          w='full'
          h='full'
          bgColor='white'
          _dark={{ bgColor: 'black' }}
        />
        <Box
          pos='relative'
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          w='full'
          h='full'
          paddingInline={{ base: 0, lg: 4 }}
        >
          <BaseSearch
            pathname={pathname}
            locationSearch={search}
            value={searchKeyword}
            onChange={handleSearchChange}
            onSubmit={handleSearchSubmit}
          />
          <CoreLogo
            pos='absolute'
            display='inline-block'
            w={{ base: '126px', sm: '138px' }}
            h={{ base: '34px', sm: '37px' }}
            left='50%'
            transform='translateX(-50%)'
            href='/'
            isHome={isHome}
            siteTitle={siteTitle}
          />
          <Center>
            <CoreSubmitLink
              to={submitLink}
              containerProps={{ mr: { base: 0, lg: 2 } }}
            />
            <CoreNav
              pathname={pathname}
              primaryMenuLinks={primaryMenuLinks}
              footerMenuLinks={footerMenuLinks}
            />
          </Center>
        </Box>
        <CoreBreadcrumb siteUrl={siteUrl} pathname={pathname} />
      </chakra.header>
    </Headroom>
  );
}

RCoreHeader.propTypes = {
  siteUrl: PropTypes.string,
  siteTitle: PropTypes.string,
  primaryMenuLinks: PropTypes.array,
  footerMenuLinks: PropTypes.array,
};

RCoreHeader.defaultProps = {
  siteUrl: '',
  siteTitle: '',
  primaryMenuLinks: [],
  footerMenuLinks: [],
};

export const CoreHeader = React.memo(RCoreHeader);
