import React from 'react';
import {
  Button,
  Divider,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { Hamburger } from 'phosphor-react';
import PropTypes from 'prop-types';

import { BaseHeading } from '@components/base';

export function RCoreNav({ pathname, primaryMenuLinks, footerMenuLinks }) {
  const { isOpen, onToggle, onClose } = useDisclosure();

  React.useEffect(() => {
    onClose();
  }, [pathname, onClose]);

  return (
    <>
      <IconButton
        aria-label='main menu'
        variant='ghost'
        icon={<Icon as={Hamburger} mt='2px' h='28px' w='28px' />}
        onClick={onToggle}
      />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: 'full', lg: 'xs' }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          pt={{ base: '60px', lg: 0 }}
          pos={{ base: 'absolute', lg: 'relative' }}
          top={{ base: 0, lg: 'unset' }}
          containerProps={{ zIndex: 100001 }}
        >
          <ModalHeader
            position={{ base: 'fixed', lg: 'unset' }}
            width={{ base: 'full', lg: 'fit-content' }}
            top={{ base: 0, lg: 'unset' }}
            zIndex={1}
            _before={{
              base: {
                content: '""',
                pos: 'absolute',
                top: 0,
                left: 0,
                w: 'full',
                h: 'full',
                bgColor: 'bgDark',
              },
              lg: {
                content: 'unset',
              },
            }}
          >
            <BaseHeading
              containerProps={{ pos: 'relative', zIndex: 1 }}
              icon={Hamburger}
            >
              Menu
            </BaseHeading>
          </ModalHeader>
          <ModalCloseButton
            __css={{
              pos: { base: 'fixed', lg: 'absolute' },
              top: 2,
              right: 3,
              zIndex: 2,
            }}
          />
          <ModalBody pt={0} pb={4}>
            <VStack as='nav' aria-label='primary' spacing={0}>
              {primaryMenuLinks.map(({ label, to }) => (
                <Button
                  key={to}
                  as='a'
                  w='full'
                  href={to}
                  variant='ghost'
                  size='lg'
                >
                  {label}
                </Button>
              ))}
            </VStack>
            <Divider marginBlock={4} />
            <VStack as='nav' aria-label='secondary'>
              {footerMenuLinks.map(({ label, to }) => (
                <Button
                  key={to}
                  as='a'
                  w='full'
                  href={to}
                  variant='ghost'
                  size='sm'
                >
                  {label}
                </Button>
              ))}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

RCoreNav.propTypes = {
  pathname: PropTypes.string,
  primaryMenuLinks: PropTypes.array,
  footerMenuLinks: PropTypes.array,
};

RCoreNav.defaultProps = {
  pathname: '',
  primaryMenuLinks: [],
  footerMenuLinks: [],
};

export const CoreNav = React.memo(RCoreNav);
