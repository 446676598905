import React from 'react';
import { Box, Center, Fade, Flex } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { Banner, Outstream } from 'exoclick-react';

import { BaseLoading } from './base-loading.component';

export function BaseScene({ children, ...moreProps }) {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const delay = setTimeout(() => {
      setLoading(false);
    }, 800);

    return () => {
      clearTimeout(delay);
    };
  }, []);

  return (
    <>
      <Fade in={!loading}>
        <Flex
          flexDir={{ base: 'column', xl: 'row' }}
          justifyContent={{ base: 'flex-start', xl: 'center' }}
          alignItems={{ base: 'center', xl: 'flex-start' }}
          paddingInline={4}
          paddingBlock='30px 18px'
          {...moreProps}
        >
          <Box w='full' maxW='936px'>
            <Center>
              <Banner zoneId={4856160} />
            </Center>
            <div
              style={{
                margin: '0 auto 8px auto',
                maxWidth: '400px',
                width: '100%',
              }}
            >
              <Outstream zoneId={4856206} maxWidth={400} />
            </div>
            {children}
          </Box>
        </Flex>
      </Fade>
      <Fade in={loading} unmountOnExit>
        <BaseLoading
          pos='fixed'
          top={0}
          l={0}
          w='100vw'
          h='100vh'
          zIndex={8}
          bgColor='white'
          _dark={{ bgColor: 'bgDark' }}
        />
      </Fade>
    </>
  );
}

BaseScene.propTypes = {
  children: PropTypes.node.isRequired,
};

BaseScene.defaultProps = {
  children: undefined,
};
