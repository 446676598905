import React from 'react';
import { Box, Button, Hide, Icon, IconButton, Show } from '@chakra-ui/react';
import { VideoCamera } from 'phosphor-react';
import PropTypes from 'prop-types';

function RCoreSubmitLink({ to, containerProps, ...moreProps }) {
  return (
    <Box {...containerProps}>
      <Hide above='lg'>
        <IconButton
          as='a'
          aria-label='main menu'
          href={to}
          variant='ghost'
          icon={<Icon as={VideoCamera} mt='2px' h='28px' w='28px' />}
        />
      </Hide>
      <Show above='lg'>
        <Button
          as='a'
          aria-label='submit video'
          href={to}
          size='sm'
          leftIcon={<VideoCamera />}
          {...moreProps}
        >
          Submit
        </Button>
      </Show>
    </Box>
  );
}

RCoreSubmitLink.propTypes = {
  containerProps: PropTypes.object,
};

export const CoreSubmitLink = React.memo(RCoreSubmitLink);
