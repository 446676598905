import React from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

function RCoreBreadcrumb({ siteUrl, pathname, ...moreProps }) {
  const items = React.useMemo(
    () =>
      pathname
        .trim()
        .split('/')
        .filter((s) => s),
    [pathname]
  );

  return (
    <Breadcrumb id='breadcrumb' separator='/' hidden {...moreProps}>
      <BreadcrumbItem>
        <BreadcrumbLink href='/'>home</BreadcrumbLink>
      </BreadcrumbItem>
      {items.map((item, index) => (
        <BreadcrumbItem key={item}>
          {index >= items.length - 1 ? (
            <BreadcrumbLink href={`${pathname}`}>
              {item.replace('-', ' ')}
            </BreadcrumbLink>
          ) : (
            <Text as='span'>{item.replace('-', ' ')}</Text>
          )}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
}

RCoreBreadcrumb.propTypes = {
  siteUrl: PropTypes.string,
  pathname: PropTypes.string,
};

export const CoreBreadcrumb = React.memo(RCoreBreadcrumb);
