import React from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Icon,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { ArrowFatLeft, ArrowFatRight } from 'phosphor-react';
import PropTypes from 'prop-types';

import { BasePaginationBeforeButtons } from './base-pagination-before-buttons.component';
import { BasePaginationAfterButtons } from './base-pagination-after-buttons.component';
import { BasePaginationPagePicker } from './base-pagination-page-picker.component';

function RBasePagination({
  numPages,
  currentPage,
  toPath,
  onSelectPage,
  ...moreProps
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleNavigateSelectedPage = React.useCallback(
    (targetPage) => {
      if (targetPage > numPages) return;

      if (!!onSelectPage) {
        onSelectPage(targetPage);
      } else {
        const targetPath = targetPage > 1 ? `${toPath}/${targetPage}` : toPath;
        // navigate(targetPath);
        window.location.href = targetPath;
      }
    },
    [toPath, numPages, onSelectPage]
  );

  const handleNavigatePrevPage = React.useCallback(
    () => handleNavigateSelectedPage(currentPage - 1),
    [handleNavigateSelectedPage, currentPage]
  );

  const handleNavigateNextPage = React.useCallback(
    () => handleNavigateSelectedPage(currentPage + 1),
    [handleNavigateSelectedPage, currentPage]
  );

  return (
    <>
      <Flex
        justifyContent='space-between'
        alignItems='center'
        minW={{ base: 'auto', md: '500px' }}
        {...moreProps}
      >
        <IconButton
          aria-label='previous page'
          variant='ghost'
          icon={<Icon as={ArrowFatLeft} />}
          disabled={currentPage <= 1}
          onClick={handleNavigatePrevPage}
        />
        <ButtonGroup variant='outline' size='sm' isAttached>
          <BasePaginationBeforeButtons
            currentPage={currentPage}
            onNavigateSelectedPage={handleNavigateSelectedPage}
            onPagePick={onOpen}
          />
          <Button
            aria-label={`page ${currentPage}`}
            borderRadius='md'
            color='accent'
          >
            {currentPage}
            <Box pos='absolute' bottom={0} w='full' h='2px' bgColor='accent' />
          </Button>
          <BasePaginationAfterButtons
            currentPage={currentPage}
            numPages={numPages}
            onNavigateSelectedPage={handleNavigateSelectedPage}
            onPagePick={onOpen}
          />
        </ButtonGroup>
        <IconButton
          aria-label='next page'
          variant='ghost'
          icon={<Icon as={ArrowFatRight} />}
          disabled={currentPage >= numPages}
          onClick={handleNavigateNextPage}
        />
      </Flex>
      <BasePaginationPagePicker
        isOpen={isOpen}
        onClose={onClose}
        currentPage={currentPage}
        maxPage={numPages}
        onSubmit={handleNavigateSelectedPage}
      />
    </>
  );
}

RBasePagination.propTypes = {
  numPages: PropTypes.number,
  currentPage: PropTypes.number,
  toPath: PropTypes.string,
  onSelectPage: PropTypes.func,
};

RBasePagination.defaultProps = {
  numPages: 0,
  currentPage: 0,
  toPath: '/',
};

export const BasePagination = React.memo(RBasePagination);
