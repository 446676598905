import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';

const query = graphql`
  query Seo {
    site {
      siteMetadata {
        defaultTitle: title
        defaultTitleDescription: titleDescription
        defaultDescription: description
        siteUrl
        keywords
      }
    }
  }
`;

function RBaseSeo({
  title,
  description,
  shareUrl,
  imageUrl,
  isPageVideo,
  schemaGraphs,
  children,
}) {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);
  const defaultTitle = React.useMemo(
    () => site.siteMetadata?.defaultTitle,
    [site]
  );
  const { defaultTitleDescription, defaultDescription } = React.useMemo(
    () => site.siteMetadata || {},
    [site]
  );
  const siteUrl = React.useMemo(() => site.siteMetadata?.siteUrl, [site]);
  const siteTitle = React.useMemo(
    () => (title ? `${title} ∘ ${defaultTitle}` : defaultTitle),
    [defaultTitle, title]
  );
  const metaDescription = React.useMemo(() => {
    if (isPageVideo) {
      return `Watch ${title}. ${defaultDescription}`;
    }

    return (
      (description || `${defaultTitleDescription} for Free`) +
      `. ${defaultDescription}`
    );
  }, [
    defaultTitleDescription,
    defaultDescription,
    description,
    title,
    isPageVideo,
  ]);
  const metalUrl = React.useMemo(
    () => shareUrl || `${siteUrl}${pathname}`,
    [siteUrl, shareUrl, pathname]
  );
  const keywords = React.useMemo(() => site.siteMetadata?.keywords, [site]);
  const metaType = isPageVideo ? 'video.movie' : 'website';
  const metaImage = imageUrl || '/media_logo.png';

  const lastCrumb = React.useMemo(() => {
    const items = pathname
      .trim()
      .split('/')
      .filter((s) => s);
    return items.length ? items[items.length - 1].replace('-', ' ') : null;
  }, [pathname]);

  const jsonSchema = React.useMemo(
    () =>
      JSON.stringify({
        '@context': 'https://schema.org',
        '@graph': [
          ...schemaGraphs,
          {
            '@type': 'BreadcrumbList',
            '@id': `${siteUrl}/#breadcrumb`,
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: 'Home',
                ...(lastCrumb && { item: siteUrl }),
              },
              ...(lastCrumb
                ? [
                    {
                      '@type': 'ListItem',
                      position: 2,
                      name:
                        lastCrumb.charAt(0).toUpperCase() + lastCrumb.slice(1),
                    },
                  ]
                : []),
            ],
          },
          {
            '@type': 'WebSite',
            '@id': `${siteUrl}/#website`,
            url: siteUrl,
            name: defaultTitle,
            description: defaultTitleDescription,
            publisher: {
              '@type': 'Organization',
              name: defaultTitle,
              '@id': `${siteUrl}/#organization`,
            },
            potentialAction: [
              {
                '@type': 'SearchAction',
                target: {
                  '@type': 'EntryPoint',
                  urlTemplate: `${siteUrl}/search?q={search_term_string}`,
                },
                'query-input': 'required name=search_term_string',
              },
            ],
            inLanguage: 'en-US',
          },
          {
            '@type': 'Organization',
            '@id': `${siteUrl}/#organization`,
            name: defaultTitle,
            url: siteUrl,
            logo: {
              '@type': 'ImageObject',
              inLanguage: 'en-US',
              '@id': `${siteUrl}/#/schema/logo/image/`,
              url: `${siteUrl}/media_logo.png`,
              contentUrl: `${siteUrl}/media_logo.png`,
              caption: siteUrl,
            },
            image: {
              '@id': `${siteUrl}/#/schema/logo/image/`,
            },
          },
        ],
      }),
    [schemaGraphs, siteUrl, defaultTitle, defaultTitleDescription, lastCrumb]
  );

  return (
    <>
      <title id='site-title'>{siteTitle}</title>
      <meta name='description' content={metaDescription} />
      <meta name='keywords' content={keywords} />
      <meta property='og:title' content={siteTitle} />
      <meta property='og:description' content={metaDescription} />
      <meta property='og:type' content={metaType} />
      <meta property='og:url' content={metalUrl} />
      <meta property='og:image' content={metaImage} />
      {isPageVideo && <meta property='og:video:type' content='text/html' />}
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:title' content={siteTitle} />
      <meta name='twitter:url' content={metalUrl} />
      <meta name='twitter:site' content={`@${defaultTitle.toLowerCase()}`} />
      <meta name='twitter:domain' content={siteUrl} />
      <meta name='twitter:creator' content={`@${defaultTitle.toLowerCase()}`} />
      <meta name='twitter:description' content={metaDescription} />
      <meta name='twitter:image' content={metaImage} />
      <meta name='RATING' content='RTA-5042-1996-1400-1577-RTA' />
      {imageUrl && <link rel='preload' as='image' href={metaImage} />}
      {children}
      <script type='application/ld+json'>{jsonSchema}</script>
    </>
  );
}

RBaseSeo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  shareUrl: PropTypes.string,
  imageUrl: PropTypes.string,
  isPageVideo: PropTypes.bool,
  schemaGraphs: PropTypes.array,
};

RBaseSeo.defaultProps = {
  isPageVideo: false,
  schemaGraphs: [],
};

export const BaseSeo = React.memo(RBaseSeo);
