import React from 'react';
import { useStaticQuery, graphql, Script } from 'gatsby';
import { Box, chakra, useColorMode } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { StickyBanner } from 'exoclick-react';

import { CoreHeader } from './core-header.component';
import { CoreFooter } from './core-footer.component';

const query = graphql`
  query SiteDataQuery {
    site {
      siteMetadata {
        siteUrl
        title
        primaryMenuLinks {
          label
          to
        }
        footerMenuLinks {
          label
          to
        }
      }
    }
  }
`;

export function CoreLayout({ children }) {
  const { site } = useStaticQuery(query);
  const { colorMode, setColorMode } = useColorMode();
  const { siteUrl, title } = React.useMemo(
    () => site.siteMetadata || {},
    [site]
  );
  const primaryMenuLinks = React.useMemo(
    () => site.siteMetadata?.primaryMenuLinks || [],
    [site]
  );
  const footerMenuLinks = React.useMemo(
    () => site.siteMetadata?.footerMenuLinks || [],
    [site]
  );

  React.useEffect(() => {
    if (colorMode === 'dark') return;
    setColorMode('dark');
  }, [colorMode, setColorMode]);

  return (
    <Box overflowY='auto'>
      <CoreHeader
        siteUrl={siteUrl}
        siteTitle={title}
        primaryMenuLinks={primaryMenuLinks}
        footerMenuLinks={footerMenuLinks}
      />
      <chakra.main className='core-main' minH='calc(100vh - 56px - 99px)'>
        {children}
      </chakra.main>
      <CoreFooter siteTitle={title} footerMenuLinks={footerMenuLinks} />
      <Script
        dangerouslySetInnerHTML={{
          __html: `(AdProvider = window.AdProvider || []).push({"serve": {}});`,
        }}
      />
      <StickyBanner
        zoneId={4856052}
        horizontalPosition='center'
        verticalPosition='bottom'
        format='300x250'
      />
      <Script
        type='application/javascript'
        src='https://a.realsrv.com/popunder1000.js'
      />
    </Box>
  );
}

CoreLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

CoreLayout.defaultProps = {
  children: undefined,
};
