import React from 'react';
import { Heading } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { BaseLink } from '../base';
import logoSvg from '@images/logo.svg';

function RCoreLogo({ siteTitle, isHome, ...moreProps }) {
  const SiteTitle = React.useCallback(
    () => (
      <>
        {isHome && (
          <Heading
            as='h1'
            pos='absolute'
            m={0}
            w={0}
            h={0}
            opacity={0}
            overflow='hidden'
          >
            {siteTitle}
          </Heading>
        )}
        <img src={logoSvg} alt='site logo' />
      </>
    ),
    [isHome, siteTitle]
  );

  return (
    <BaseLink as='a' {...moreProps}>
      <SiteTitle />
    </BaseLink>
  );
}

RCoreLogo.propTypes = {
  isHome: PropTypes.bool,
  siteTitle: PropTypes.string,
};

RCoreLogo.defaultProps = {
  isHome: false,
  siteTitle: '',
};

export const CoreLogo = React.memo(RCoreLogo);
