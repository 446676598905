import React from 'react';
import {
  Button,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { BaseHeading } from './base-heading.component';

function RBasePaginationPagePicker({
  currentPage,
  maxPage,
  isOpen,
  onClose,
  onSubmit,
}) {
  const [targetPage, setTargetPage] = React.useState(currentPage);
  const initialRef = React.useRef(null);

  const handleFocus = React.useCallback((event) => {
    event.target.select();
  }, []);

  const handleSubmit = React.useCallback(() => {
    onSubmit(targetPage);
    onClose();
  }, [targetPage, onSubmit, onClose]);

  const handleKeyUp = (event) => {
    if (event.key !== 'Enter') return;
    handleSubmit();
  };

  const handleClose = React.useCallback(() => {
    setTargetPage(currentPage);
    onClose();
  }, [currentPage, onClose]);

  return (
    <Modal
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={handleClose}
      size={{ base: 'full', xs: 'xs' }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        maxW={{ base: 'full', lg: '260px' }}
        containerProps={{ zIndex: 100001 }}
      >
        <ModalHeader>
          <BaseHeading>Go to Page</BaseHeading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <NumberInput
              aria-label='page field'
              defaultValue={targetPage}
              min={1}
              max={maxPage}
              onFocus={handleFocus}
              onChange={setTargetPage}
              onKeyUp={handleKeyUp}
            >
              <NumberInputField ref={initialRef} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button size='sm' onClick={handleSubmit}>
            Select
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

RBasePaginationPagePicker.propTypes = {
  currentPage: PropTypes.number,
  maxPage: PropTypes.number,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

RBasePaginationPagePicker.defaultProps = {
  isOpen: false,
  onClose: () => null,
};

export const BasePaginationPagePicker = React.memo(RBasePaginationPagePicker);
