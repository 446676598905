import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Link } from '@chakra-ui/react';
import PropTypes from 'prop-types';

function RBaseLink({ children, ...moreProps }) {
  return (
    <Link as={GatsbyLink} {...moreProps}>
      {children}
    </Link>
  );
}

RBaseLink.propTypes = {
  children: PropTypes.node,
};

RBaseLink.defaultProps = {
  children: undefined,
};

export const BaseLink = React.memo(RBaseLink);
