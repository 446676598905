import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

const breakpoints = {
  xs: '320px',
  '2xs': '375px',
  sm: '500px',
  '2sm': '570px',
  '3sm': '600px',
  '4sm': '650px',
  md: '768px',
  '2md': '940px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px',
};

const colors = {
  primary: '#d31027',
  secondary: '#ea384d',
  accent: '#edff00',
  bgDark: '#191919',
};

const fonts = {
  heading: 'edusabeginner, sans-serif',
  body: 'edusabeginner, sans-serif',
  accent: 'outfit, sans-serif',
};

const inputFilledStyle = {
  bg: 'rgba(255,255,255,0.1)',
  _placeholder: { color: 'rgba(255,255,255,0.8)' },
};

const styles = {
  global: (props) => ({
    html: { fontSize: '18px' },
    body: {
      bg: mode('white', colors.bgDark)(props),
      fontFamily: fonts.body,
    },
  }),
};

const components = {
  Button: {
    baseStyle: {
      borderRadius: 'full',
      cursor: 'pointer',
    },
    variants: {
      solid: {
        paddingInline: 4,
        _hover: {
          bg: 'primary',
        },
      },
    },
  },
  Heading: {
    defaultProps: {
      size: 'md',
    },
  },
  Input: {
    baseStyle: {
      field: { lineHeight: '3rem' },
    },
    variants: {
      filled: {
        field: {
          ...inputFilledStyle,
        },
      },
    },
    defaultProps: {
      variant: 'filled',
    },
  },
  NumberInput: {
    defaultProps: {
      variant: 'filled',
    },
  },
  Menu: {
    baseStyle: (props) => ({
      menu: {},
      item: {},
      list: {
        bg: mode('white', colors.bgDark)(props),
      },
    }),
  },
  Modal: {
    baseStyle: (props) => ({
      dialog: {
        bg: mode('white', colors.bgDark)(props),
      },
    }),
  },
  Select: {
    baseStyle: {
      field: {
        lineHeight: '2rem',
        cursor: 'pointer',
      },
    },
    variants: {
      filled: {
        field: {
          ...inputFilledStyle,
        },
      },
    },
    defaultProps: {
      variant: 'filled',
    },
  },
  Textarea: {
    variants: {
      filled: {
        ...inputFilledStyle,
      },
    },
    defaultProps: {
      variant: 'filled',
    },
  },
  Tooltip: {
    baseStyle: {
      fontFamily: 'accent',
      fontSize: 'sm',
    },
  },
};

export default extendTheme({
  styles,
  config,
  breakpoints,
  fonts,
  colors,
  components,
});
