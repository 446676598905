import React from 'react';
import {
  Box,
  FormControl,
  Hide,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Show,
  useDisclosure,
} from '@chakra-ui/react';
import { MagnifyingGlass } from 'phosphor-react';
import PropTypes from 'prop-types';

const SearchField = React.memo(
  React.forwardRef(({ placeholder, ...moreProps }, ref) => (
    <InputGroup>
      <InputLeftElement
        h='full'
        pointerEvents='none'
        children={<Icon as={MagnifyingGlass} />}
      />
      <Input
        ref={ref}
        maxW='450px'
        fontSize='md'
        borderRadius='full'
        placeholder={placeholder}
        size='sm'
        type='search'
        {...moreProps}
      />
    </InputGroup>
  ))
);

function RBaseSearch({
  pathname,
  locationSearch,
  search,
  placeholder,
  onSubmit,
  ...moreProps
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.createRef();

  React.useEffect(() => {
    onClose();
  }, [pathname, locationSearch, onClose]);

  return (
    <>
      <Box>
        <Hide above='lg'>
          <IconButton
            aria-label={placeholder}
            variant='unstyled'
            icon={<Icon as={MagnifyingGlass} mt='8px' h='24px' w='24px' />}
            onClick={onOpen}
          />
        </Hide>
        <Show above='lg'>
          <Box as='form' w='300px' onSubmit={onSubmit}>
            <SearchField placeholder={placeholder} {...moreProps} />
          </Box>
        </Show>
      </Box>
      <Hide above='lg'>
        <Modal
          size={{ base: 'full', '2xs': 'xs', sm: 'md' }}
          initialFocusRef={initialRef}
          isOpen={isOpen}
          onClose={onClose}
          isCentered
        >
          <ModalOverlay />
          <ModalContent containerProps={{ zIndex: 100001 }}>
            <ModalCloseButton />
            <ModalBody pb={0} pt={{ base: 8, '2xs': 0 }}>
              <Box
                as='form'
                paddingInline={4}
                paddingBlock={6}
                onSubmit={onSubmit}
              >
                <FormControl>
                  <SearchField
                    ref={initialRef}
                    placeholder={placeholder}
                    {...moreProps}
                  />
                </FormControl>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Hide>
    </>
  );
}

RBaseSearch.propTypes = {
  pathname: PropTypes.string,
  locationSearch: PropTypes.string,
  placeholder: PropTypes.string,
  onSubmit: PropTypes.func,
};

RBaseSearch.defaultProps = {
  pathname: '',
  locationSearch: '',
  placeholder: 'Find Videos',
  onSubmit: () => null,
};

export const BaseSearch = React.memo(RBaseSearch);
