import React from 'react';
import { Box, chakra, Image, Link, Stack, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { BaseLink } from '@components/base';
import rtaLogo from '@images/88x31_RTA-5042-1996-1400-1577-RTA_c.png';

function RCoreFooter({ siteTitle, footerMenuLinks }) {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <chakra.footer className='core-footer' paddingInline={4} paddingBlock={8}>
        <Stack
          as='nav'
          aria-label='footer'
          direction={{ base: 'column', '4sm': 'row' }}
          justify='center'
          align='center'
          fontSize='sm'
          lineHeight={2.2}
          divider={
            <NavDivider display={{ base: 'none', '4sm': 'inline-block' }} />
          }
        >
          {footerMenuLinks.map(({ label, to }) => (
            <BaseLink key={to} as='a' href={to}>
              {label.toLowerCase()}
            </BaseLink>
          ))}
          <Text as='span'>
            {siteTitle} © {currentYear}
          </Text>
        </Stack>
        <Box mt={2} textAlign='center'>
          <Link
            display='inline-block'
            href='https://www.rtalabel.org'
            isExternal
          >
            <Image alignSelf='center' w={88} h={31} src={rtaLogo} alt='rta' />
          </Link>
        </Box>
      </chakra.footer>
    </>
  );
}

RCoreFooter.propTypes = {
  siteTitle: PropTypes.string,
  footerMenuLinks: PropTypes.array,
};

RCoreFooter.defaultProps = {
  siteTitle: '',
  footerMenuLinks: [],
};

function RNavDivider({ ...moreProps }) {
  return (
    <Text as='span' display='inline-block' paddingInline={2} {...moreProps}>
      ◦
    </Text>
  );
}

const NavDivider = React.memo(RNavDivider);

export const CoreFooter = React.memo(RCoreFooter);
