import React from 'react';
import { Button } from '@chakra-ui/react';
import PropTypes from 'prop-types';

function RBasePaginationAfterButtons({
  currentPage,
  numPages,
  onNavigateSelectedPage,
  onPagePick,
}) {
  const handleNavigatePrevPage = React.useCallback(
    () => onNavigateSelectedPage(numPages - 1),
    [onNavigateSelectedPage, numPages]
  );

  const handleNavigateNextPage = React.useCallback(
    () => onNavigateSelectedPage(numPages),
    [onNavigateSelectedPage, numPages]
  );

  if (currentPage >= numPages) return null;

  return (
    <>
      {[1, 2]
        .filter((x) => currentPage + x < numPages)
        .map((x) => {
          const targetPage = currentPage + x;
          return (
            <Button
              key={`page-${targetPage}`}
              arial-label={`page ${targetPage}`}
              borderRadius='md'
              onClick={() => onNavigateSelectedPage(targetPage)}
            >
              {targetPage}
            </Button>
          );
        })}
      {numPages - currentPage > 4 && (
        <Button aria-label='select page' borderRadius='md' onClick={onPagePick}>
          ...
        </Button>
      )}
      {numPages - currentPage === 4 && (
        <Button
          aria-label={`page ${numPages - 1}`}
          borderRadius='md'
          onClick={handleNavigatePrevPage}
        >
          {numPages - 1}
        </Button>
      )}
      <Button
        key={`page-${numPages}`}
        aria-label={numPages}
        borderRadius='md'
        onClick={handleNavigateNextPage}
      >
        {numPages}
      </Button>
    </>
  );
}

RBasePaginationAfterButtons.propTypes = {
  currentPage: PropTypes.number,
  numPages: PropTypes.number,
  onNavigateSelectedPage: PropTypes.func,
  onPagePick: PropTypes.func,
};

RBasePaginationAfterButtons.defaultProps = {
  currentPage: 0,
  numPages: 0,
  onNavigateSelectedPage: () => null,
  onPagePick: () => null,
};

export const BasePaginationAfterButtons = React.memo(
  RBasePaginationAfterButtons
);
