import React from 'react';
import { Flex, Heading, Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

function RBaseHeading({ icon, containerProps, children, ...moreProps }) {
  return (
    <Flex alignItems='center' {...containerProps}>
      {icon && <Icon as={icon} mr={1} />}
      <Heading
        as='h4'
        fontSize='md'
        fontFamily='accent'
        fontWeight='normal'
        textTransform='uppercase'
        lineHeight={1.2}
        {...moreProps}
      >
        {children}
      </Heading>
    </Flex>
  );
}

RBaseHeading.propTypes = {
  icon: PropTypes.any,
  containerProps: PropTypes.object,
  children: PropTypes.node,
};

export const BaseHeading = React.memo(RBaseHeading);
