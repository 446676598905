import React from 'react';
import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  Progress,
} from '@chakra-ui/react';
import { FileVideo } from 'phosphor-react';
import PropTypes from 'prop-types';

const RBaseFileUpload = React.forwardRef(
  ({ value, isUploading, accept, onChange, ...moreProps }, ref) => {
    return (
      <Box>
        <Progress
          w='full'
          borderTopRadius='md'
          size='sm'
          colorScheme='teal'
          value={0}
          isIndeterminate={isUploading}
        />
        <InputGroup
          cursor='pointer'
          _hover={{ opacity: 0.6 }}
          onClick={() => ref.current?.click()}
        >
          <Input
            ref={ref}
            type='file'
            accept={accept}
            onChange={onChange}
            hidden
            {...moreProps}
          />
          <InputLeftAddon
            bgColor='rgba(255,255,255,0.35)'
            borderTopLeftRadius={0}
            children={<Icon as={FileVideo} w={6} h={6} />}
          />
          <Input
            borderTopRightRadius={0}
            pointerEvents='none'
            value={value}
            required={false}
            readOnly
          />
        </InputGroup>
      </Box>
    );
  }
);

RBaseFileUpload.propTypes = {
  value: PropTypes.string,
  isUploading: PropTypes.bool,
  accept: PropTypes.string,
  onChange: PropTypes.func,
};

RBaseFileUpload.defaultProps = {
  accept:
    'video/*,.ts,.zip,.rar,.7z,.gz,image/tiff,image/jfif,image/bmp,image/apng,image/gif,image/png,image/jpeg,image/jpg,image/webp,image/tif,image/pjpeg,image/avif',
};

export const BaseFileUpload = React.memo(RBaseFileUpload);
