import React from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  Input,
  List,
  ListItem,
  ListIcon,
  Text,
  Textarea,
  VStack,
  Center,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { XCircle } from 'phosphor-react';
import PropTypes from 'prop-types';

const schema = z.object({
  email: z.string().email('Provide your email address'),
  content: z.string('Provide email content'),
});

const defaultValues = {
  email: '',
  content: '',
};

export function RCoreContactUsForm({ onSubmit, ...moreProps }) {
  const methods = useForm({
    shouldFocusError: false,
    defaultValues,
    resolver: zodResolver(schema),
  });
  const {
    control,
    formState: { errors, isSubmitting, isDirty, isSubmitSuccessful },
    handleSubmit: submitForm,
    watch,
    reset,
  } = methods;
  const [fieldContentLength, setFieldContentLength] = React.useState(0);
  const formRef = React.useRef(null);

  const fieldContent = watch('content', '');

  React.useEffect(() => {
    setFieldContentLength(fieldContent.length);
  }, [fieldContent]);

  const formErrors = React.useMemo(
    () =>
      errors
        ? Object.values(errors).map(({ message }, index) => ({
            id: index,
            message,
          }))
        : [],
    [errors]
  );

  const handleReset = React.useCallback(() => {
    reset();
  }, [reset]);

  React.useEffect(() => {
    if (!isSubmitSuccessful || !isDirty) return;
    handleReset();
  }, [isDirty, isSubmitSuccessful, handleReset]);

  const handleSubmit = React.useCallback(
    (event) => {
      event.preventDefault();

      if (!onSubmit || !formRef.current) return;

      submitForm(async () => {
        await onSubmit(formRef.current);
      })();
    },
    [formRef, submitForm, onSubmit]
  );

  return (
    <Box as='form' ref={formRef} onSubmit={handleSubmit} {...moreProps}>
      <VStack spacing={4} pb={4}>
        <input
          type='hidden'
          name='site'
          value={process.env.GATSBY_SITEMETA_TITLE}
        />
        <Controller
          name='email'
          control={control}
          render={({ field, formState: { errors } }) => (
            <FormControl isInvalid={errors.email}>
              <Input
                aria-label='email address'
                placeholder='Email address'
                {...field}
              />
            </FormControl>
          )}
        />
        <Controller
          name='content'
          control={control}
          render={({ field, formState: { errors } }) => (
            <FormControl isInvalid={errors.content}>
              <Textarea
                minH={32}
                aria-label='your message'
                placeholder='Your message'
                {...field}
              />
              <Text
                as='small'
                display='inline-block'
                w='full'
                fontFamily='accent'
                textAlign='center'
              >
                {fieldContentLength}/∞
              </Text>
            </FormControl>
          )}
        />
        <Text as='small' fontFamily='accent'>
          Get in touch. Fill out the form with your inquiry or message.
        </Text>
        {!!formErrors.length && (
          <Box w='full'>
            <Divider />
            <List paddingBlock={2}>
              {formErrors.map(({ id, message }) => (
                <ListItem key={id}>
                  <ListIcon as={XCircle} color='red.500' />
                  <Text as='small' fontFamily='accent'>
                    {message}
                  </Text>
                </ListItem>
              ))}
            </List>
            <Divider />
          </Box>
        )}
      </VStack>
      <Center flexDir='column' pt={4}>
        <ButtonGroup spacing='2'>
          <Button size='sm' variant='ghost' onClick={handleReset}>
            Reset
          </Button>
          <Button
            w='66px'
            size='sm'
            onClick={handleSubmit}
            isLoading={isSubmitting}
            type='submit'
          >
            Send
          </Button>
        </ButtonGroup>
      </Center>
    </Box>
  );
}

RCoreContactUsForm.propTypes = {
  onSubmit: PropTypes.func,
};

RCoreContactUsForm.defaultProps = {
  onSubmit: undefined,
};

export const CoreContactUsForm = React.memo(RCoreContactUsForm);
