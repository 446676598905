import React from 'react';
import { Button } from '@chakra-ui/react';
import PropTypes from 'prop-types';

function RBasePaginationBeforeButtons({
  currentPage,
  onNavigateSelectedPage,
  onPagePick,
}) {
  const handleNavigateFirstPage = React.useCallback(
    () => onNavigateSelectedPage(1),
    [onNavigateSelectedPage]
  );

  const handleNavigateSecondPage = React.useCallback(
    () => onNavigateSelectedPage(2),
    [onNavigateSelectedPage]
  );

  if (currentPage <= 1) return null;

  return (
    <>
      <Button
        key='page-1'
        aria-label='page 1'
        borderRadius='md'
        onClick={handleNavigateFirstPage}
      >
        1
      </Button>
      {currentPage > 5 && (
        <Button aria-label='select page' borderRadius='md' onClick={onPagePick}>
          ...
        </Button>
      )}
      {currentPage === 5 && (
        <Button
          aria-label='page 2'
          borderRadius='md'
          onClick={handleNavigateSecondPage}
        >
          2
        </Button>
      )}
      {[2, 1]
        .filter((x) => currentPage - x > 1)
        .map((x) => {
          const targetPage = currentPage - x;
          return (
            <Button
              key={`page-${targetPage}`}
              arial-label={`page ${targetPage}`}
              borderRadius='md'
              onClick={() => onNavigateSelectedPage(targetPage)}
            >
              {targetPage}
            </Button>
          );
        })}
    </>
  );
}

RBasePaginationBeforeButtons.propTypes = {
  currentPage: PropTypes.number,
  onNavigateSelectedPage: PropTypes.func,
  onPagePick: PropTypes.func,
};

RBasePaginationBeforeButtons.defaultProps = {
  currentPage: 0,
  onNavigateSelectedPage: () => null,
  onPagePick: () => null,
};

export const BasePaginationBeforeButtons = React.memo(
  RBasePaginationBeforeButtons
);
