exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-longest-1-jsx": () => import("./../../../src/pages/longest/1.jsx" /* webpackChunkName: "component---src-pages-longest-1-jsx" */),
  "component---src-pages-newest-1-jsx": () => import("./../../../src/pages/newest/1.jsx" /* webpackChunkName: "component---src-pages-newest-1-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-submit-jsx": () => import("./../../../src/pages/submit.jsx" /* webpackChunkName: "component---src-pages-submit-jsx" */),
  "component---src-pages-tags-jsx": () => import("./../../../src/pages/tags.jsx" /* webpackChunkName: "component---src-pages-tags-jsx" */),
  "component---src-pages-trending-1-jsx": () => import("./../../../src/pages/trending/1.jsx" /* webpackChunkName: "component---src-pages-trending-1-jsx" */),
  "component---src-templates-home-template-jsx": () => import("./../../../src/templates/home.template.jsx" /* webpackChunkName: "component---src-templates-home-template-jsx" */),
  "component---src-templates-policy-template-jsx": () => import("./../../../src/templates/policy.template.jsx" /* webpackChunkName: "component---src-templates-policy-template-jsx" */),
  "component---src-templates-tape-list-queryless-template-jsx": () => import("./../../../src/templates/tape-list-queryless.template.jsx" /* webpackChunkName: "component---src-templates-tape-list-queryless-template-jsx" */),
  "component---src-templates-tape-list-template-jsx": () => import("./../../../src/templates/tape-list.template.jsx" /* webpackChunkName: "component---src-templates-tape-list-template-jsx" */),
  "component---src-templates-tape-watch-template-jsx": () => import("./../../../src/templates/tape-watch.template.jsx" /* webpackChunkName: "component---src-templates-tape-watch-template-jsx" */)
}

